import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { TextArea, ValidationStates } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Textarea",
  "componentId": "textarea",
  "description": "Textareas are used to gather text information from a user.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Documentation in progress----------------`}</strong></p>
    <h2><strong parentName="h2">{`Variants`}</strong></h2>
    <PatternExample example={<TextArea id="..." placeholder="Example" mdxType="TextArea" />} title="Neutral" mdxType="PatternExample" />
    <PatternExample example={<TextArea variant="naked" id="..." placeholder="Example" mdxType="TextArea" />} title="Naked" mdxType="PatternExample" />
    <h2><strong parentName="h2">{`Configuration`}</strong></h2>
    <PatternExample example={<TextArea id="..." size="small" placeholder="Example" mdxType="TextArea" />} title="Small" mdxType="PatternExample" />
    <PatternExample example={<TextArea id="..." size="large" placeholder="Example" mdxType="TextArea" />} title="Large" mdxType="PatternExample" />
    <h2><strong parentName="h2">{`States`}</strong></h2>
    <PatternExample example={<TextArea disabled={true} id="..." placeholder="Example" mdxType="TextArea" />} title="Disabled" mdxType="PatternExample" />
    <PatternExample example={<TextArea validationState={ValidationStates.Error} id="..." placeholder="Example" mdxType="TextArea" />} title="Error" mdxType="PatternExample" />
    <PatternExample example={<TextArea validationState={ValidationStates.Success} id="..." placeholder="Example" mdxType="TextArea" />} title="Success" mdxType="PatternExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      